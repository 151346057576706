import {
  Box,
  Heading,
  Text,
  Button,
  Image,
  GridItem,
  Grid,
  useBreakpointValue,
  Center,
  Flex,
} from "@chakra-ui/react";
import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
// import socialproof from "../assets/social-proof.png";
import socialproof from "../assets/CompaniesColor1800.png";
import step1Image from "../assets/image02.svg";
import step2Image from "../assets/image03.svg";
import step3Image from "../assets/image04.svg";
import Banner from "../components/Banner";

const Landing = () => {
  const columns = useBreakpointValue({ base: 1, md: 2 });
  const imageSize = useBreakpointValue({ base: "200px", md: "350px" });

  return (
    <>
      <Banner />
      <Navbar />
      <Box>
        <Box
          id="hero"
          bg={"primaryBackground"}
          display="flex"
          flexDirection={"column"}
          textAlign="center"
          py={{ base: 20, xl: 20 }}
          alignItems={"center"}
          px={{ base: 5, xl: 50 }}
        >
          <Box
            textAlign="center"
            px={{ xl: 150 }}
            maxW={{ base: "none", md: "80%" }}
          >
            <Heading
              color={"text"}
              as="h1"
              // bgClip="text"
              fontSize={{ base: "5xl", lg: "6xl" }}
              //   fontWeight="extrabold"
              fontWeight={"700"}
              textAlign="center"
            >
              Effortlessly{" "}
              <Text as={"span"} color={"primaryHighlight"}>
                tailor
              </Text>{" "}
              your resume using{" "}
              <Text as={"span"} color={"primaryHighlight"}>
                AI
              </Text>{" "}
              for each job application
            </Heading>
          </Box>

          <Text
            as={"p"}
            color={"text"}
            maxW={{ xl: "4xl" }}
            fontSize={23}
            fontWeight={500}
            pt={5}
          >
            Our suite of tools leverage the latest AI models to give you the
            best chances of landing an interview.
          </Text>
          {/* <Box pt="10" alignSelf={{ base: "center", md: "auto" }}></Box> */}

          <Button
            as={"a"}
            mt={20}
            width={"300px"}
            variant={"primary"}
            size={{ base: "md", md: "lg" }}
            href="https://app.resumiq.ai/register"
          >
            Try for free
          </Button>

          <Text
            color={"gray.500"}
            fontSize={"sm"}
            pt={3}
            mx={{ base: 0, md: 0 }}
          >
            No credit card required. <br /> 3 free credits upon sign-up.
          </Text>
        </Box>

        <Box
          id="socialproof"
          bg={"secondaryBackground"}
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          textAlign="center"
          py={20}
          px={{ base: 5, md: 0 }}
        >
          <Heading
            as={"h2"}
            pb={10}
            fontSize={20}
            fontWeight="500"
            color={"text"}
          >
            Our users have landed interviews at:
          </Heading>
          <Image src={socialproof} width={"800px"} />
        </Box>
        <Box id="process" pb={40} bg="primaryBackground">
          <Heading
            as="h2"
            textAlign="center"
            color="text"
            fontSize="xx-large"
            pt={20}
            pb={20}
          >
            Simple three-step process
          </Heading>

          <Flex justify="center">
            <Grid
              templateColumns={`repeat(${columns}, 1fr)`}
              gap={8}
              maxW={{ base: "100%", md: "50%" }}
              mx="auto"
            >
              <GridItem colSpan={{ base: 1, md: 1 }} order={{ base: 1, md: 1 }}>
                <Box
                  ml={{ base: 20, md: 100 }}
                  mr={{ base: 20, md: 10 }}
                  pt={{ base: 5, md: 20 }}
                  display="flex"
                  flexDirection="column"
                >
                  <Heading as="h3" size="lg" mb={2}>
                    <Text as="span" color="primaryHighlight">
                      1.
                    </Text>{" "}
                    <Text as="span" color="text">
                      Enter info about the role.
                    </Text>
                  </Heading>
                  <Text fontSize={20}>
                    Provide basic details about the job you're applying for.
                  </Text>
                </Box>
              </GridItem>
              <GridItem
                colSpan={{ base: 1, md: 1 }}
                order={{ base: 2, md: 2 }}
                display="flex"
                justifyContent="center"
                mr={{ base: 0, md: 100 }}
                pt={{ base: 0, md: 10 }}
              >
                <Box>
                  <Image src={step1Image} alt="Step 1" boxSize={imageSize} />
                </Box>
              </GridItem>

              <GridItem
                colSpan={{ base: 1, md: 1 }}
                order={{ base: 4, md: 3 }}
                display="flex"
                justifyContent="center"
              >
                <Box ml={{ base: 0, md: 100 }} pt={{ base: 0, md: 28 }}>
                  <Image src={step2Image} alt="Step 2" boxSize={imageSize} />
                </Box>
              </GridItem>
              <GridItem colSpan={{ base: 1, md: 1 }} order={{ base: 3, md: 4 }}>
                <Box
                  pt={{ base: 10, md: 40 }}
                  mr={{ base: 20, md: 100 }}
                  ml={{ base: 20, md: 10 }}
                >
                  <Heading as="h3" size="lg" mb={2}>
                    <Text as="span" color="primaryHighlight">
                      2.
                    </Text>{" "}
                    <Text as="span" color="text">
                      Select your resume.
                    </Text>
                  </Heading>
                  <Text fontSize={20}>
                    Choose a resume to use as your starting point or provide a
                    new one.
                  </Text>
                </Box>
              </GridItem>
              <GridItem
                colSpan={{ base: 1, md: 1 }}
                order={{ base: 5, md: 5 }}
                display="flex"
                justifyContent="center"
              >
                <Box
                  ml={{ base: 20, md: 100 }}
                  mr={{ base: 20, md: 0 }}
                  pt={{ base: 10, md: 32 }}
                >
                  <Heading as="h3" size="lg" mb={2}>
                    <Text as="span" color="primaryHighlight">
                      3.
                    </Text>{" "}
                    <Text as="span" color="text">
                      Get actionable advice.
                    </Text>
                  </Heading>
                  <Text fontSize={20}>
                    Our custom AI identifies specific optimizations to help you
                    get past Applicant Tracking System filters and stand out to
                    hiring managers!
                  </Text>
                </Box>
              </GridItem>
              <GridItem
                colSpan={{ base: 1, md: 1 }}
                order={{ base: 6, md: 6 }}
                display="flex"
                justifyContent="center"
              >
                <Box
                  mr={{ base: 0, md: 100 }}
                  ml={{ base: 0, md: 10 }}
                  pt={{ base: 0, md: 20 }}
                >
                  <Image src={step3Image} alt="Step 3" boxSize={imageSize} />
                </Box>
              </GridItem>
            </Grid>
          </Flex>
          <Box display={"flex"} flexDirection={"column"} pt={10}>
            <Button
              alignSelf={"center"}
              as={"a"}
              mt={20}
              width={"300px"}
              variant={"primary"}
              size={{ base: "md", md: "lg" }}
              href="https://app.resumiq.ai/register"
            >
              Try for free
            </Button>

            <Text
              alignSelf={"center"}
              textAlign={"center"}
              color={"gray.500"}
              fontSize={"sm"}
              pt={3}
              mx={{ base: 0, md: 0 }}
            >
              No credit card required. <br /> 3 free credits upon sign-up.
            </Text>
          </Box>
        </Box>
      </Box>
      <Footer />
    </>
  );
};

export default Landing;
