import React, { useState } from "react";
import {
  Box,
  Heading,
  Text,
  SimpleGrid,
  HStack,
  Button,
  Center,
  Select,
  Stack,
} from "@chakra-ui/react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

const Pricing = () => {
  const products = [
    {
      title: "5 Reviews",
      price: "7.50",
      planId: "1",
      mostPopular: "false",
      text: "Best for those casually applying, or applying to a few targeted jobs.",
    },
    {
      title: "20 Reviews",
      price: "20",
      planId: "2",
      mostPopular: "true",
      text: "Ideal for job seekers actively applying to various positions. Introductory pricing offer!",
    },
    {
      title: "80 Reviews",
      price: "40",
      planId: "3",
      mostPopular: "false",
      text: "Ideal for job seekers actively applying to multiple positions or highly competitive remote work positions.",
    },
  ];

  const [selectedCurrency, setSelectedCurrency] = useState("cad"); // Default currency

  const handleCurrencyChange = (event) => {
    setSelectedCurrency(event.target.value);
  };

  const formatPrice = (price, currency) => {
    // Define conversion rates for different currencies
    const conversionRates = {
      cad: 1,
      usd: 0.75,
      eur: 0.68,
    };

    // Define currency symbols
    const currencySymbols = {
      cad: "$", // Canadian Dollar
      usd: "$", // US Dollar
      eur: "€", // Euro
    };

    // Convert the price to the selected currency
    const convertedPrice = price * conversionRates[currency];

    // Format the price (you can use libraries like `numeral.js` for better formatting)
    const formattedPrice = convertedPrice.toFixed(2); // Assuming prices have two decimal places

    // Return an object containing the formatted price value and the currency symbol
    return {
      value: formattedPrice,
      symbol: currencySymbols[currency],
    };
  };

  return (
    <>
      <Navbar />
      <Box bg="primaryBackground" pb={{ base: 0, md: 40 }}>
        <Heading as={"h1"} textAlign="center" color={"text"} pt={20} pb={2}>
          Pricing
        </Heading>
        <Text as={"p"} fontSize={17} color={"text"} textAlign={"center"}>
          Each credit provides the ability to tailor your resume for one job
          opportunity.
        </Text>
        <SimpleGrid
          columns={{ base: 1, md: 3 }}
          spacing={10}
          //   mt={10}
          pt={10}
          px={{ base: 5, xl: 20 }}
        >
          {products.map((product, index) => (
            <Box bg="white" p={6} borderRadius="xl" boxShadow="md">
              <Heading size="lg" mb={4} fontWeight="600" color={"text"}>
                {product.title}
              </Heading>
              <HStack justifyContent="start">
                <Text
                  fontSize="4xl"
                  //   fontWeight="bold"
                  color={"primaryHighlight"}
                >
                  {formatPrice(product.price, selectedCurrency).symbol}
                </Text>
                <Text fontSize="4xl" color={"primaryHighlight"}>
                  {formatPrice(product.price, selectedCurrency).value}
                </Text>
              </HStack>
              <Text mb={4}>{product.text}</Text>

              <Button
                as={"a"}
                display="inline-flex"
                variant={"primary"}
                href="https://app.resumiq.ai/register"
              >
                Try for free
              </Button>
            </Box>
          ))}
        </SimpleGrid>

        <Center py={{ base: 20, md: 10 }}>
          <Select
            width={"250px"}
            value={selectedCurrency}
            onChange={handleCurrencyChange}
            variant="filled"
            _focus={{ borderColor: "primaryHighlight" }}
          >
            <option value="cad">CAD</option>
            <option value="usd">USD</option>
            <option value="eur">EUR</option>
          </Select>
        </Center>
      </Box>
      <Footer />
    </>
  );
};

export default Pricing;
