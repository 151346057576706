import React from "react";
import {
  Container,
  Heading,
  Text,
  Stack,
  Button,
  ListItem,
  UnorderedList,
  Box,
} from "@chakra-ui/react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

const Privacy = () => {
  return (
    <>
      <Navbar />
      <Box bg="primaryBackground">
        <Stack spacing={4} as={Container} maxW={"3xl"} py={20}>
          <Heading fontSize="3xl" fontWeight={600} color={"heading"}>
            Privacy Policy for Resumiq.ai
          </Heading>
          <Text color={"text"} fontSize={"lg"}>
            Last Updated: July 2, 2024
          </Text>

          <Text color={"text"} fontSize={"lg"}>
            Welcome to Resumiq.ai, This Privacy Policy describes how Resumiq.ai
            ("we", "us", or "our") collects, uses, and shares your personal
            information when you use our website (the "Website").
          </Text>
          <Heading fontSize="xl" fontWeight={600} color={"heading"}>
            1. Information Collection and Use
          </Heading>
          <Heading fontSize="lg" fontWeight={600} color={"heading"}>
            1.1 Personal Data
          </Heading>

          <Text color={"text"} whiteSpace="pre-wrap" fontSize={"lg"}>
            We collect the following personal information from you:
          </Text>
          <UnorderedList color={"text"} fontSize={"lg"}>
            <ListItem>
              Name: We collect your name to personalize your experience and
              communicate with you effectively.
            </ListItem>
            <ListItem>
              Email: Your email address is collected to send you important
              information regarding your orders, updates, and communication.
            </ListItem>
            <ListItem>
              Payment Information: While we collect payment details to process
              your orders securely, your payment details are neither stored nor
              sent to our servers. Our trusted third-party payment processors
              (Stripe) handle and process your payment details on our behalf.
            </ListItem>
            <ListItem>
              Resume Content: We collect your resume content to process it using
              our AI model and provide our review service to you.
            </ListItem>
          </UnorderedList>

          <Heading fontSize="lg" fontWeight={600} color={"heading"}>
            1.2 Non-personal Data
          </Heading>
          <Text color={"text"} fontSize={"lg"}>
            We may use web cookies and similar technologies to collect
            non-personal information such as your IP address, browser type,
            device information, and browsing patterns. This information helps us
            to enhance your browsing experience, analyze trends, and improve our
            services.
          </Text>

          <Heading fontSize="xl" fontWeight={600} color={"heading"}>
            2. Purpose of Data Collection
          </Heading>
          <Text color={"text"} fontSize={"lg"}>
            We collect and utilize your personal data solely for the purpose of
            providing our review service to you.
          </Text>

          <Heading fontSize="xl" fontWeight={600} color={"heading"}>
            3. Data Sharing
          </Heading>
          <Text color={"text"} fontSize={"lg"}>
            We do not share your personal data with any third parties except as
            required for delivering our service.
          </Text>

          <Heading fontSize="xl" fontWeight={600} color={"heading"}>
            4. Children's Privacy
          </Heading>
          <Text color={"text"} fontSize={"lg"}>
            Resumiq.ai is not intended for children under the age of 16. We do
            not knowingly collect personal information from children. If you are
            a parent or guardian and believe that your child has provided us
            with personal information, please contact us at the email address
            provided below.
          </Text>

          <Heading fontSize="xl" fontWeight={600} color={"heading"}>
            5. Updates to the Privacy Policy
          </Heading>
          <Text color={"text"} fontSize={"lg"}>
            We may update this Privacy Policy periodically to reflect changes in
            our practices or for other operational, legal, or regulatory
            reasons. Any updates will be posted on this page, and we may notify
            you via email about significant changes.
          </Text>
          <Heading fontSize="xl" fontWeight={600} color={"heading"}>
            9. Contact Us
          </Heading>
          <Text color={"text"} fontSize={"lg"}>
            If you have any questions about these Terms, please contact us at
            <Button
              as="a"
              href="mailto:contact@resumiq.ai"
              variant="link"
              color={"text"}
            >
              contact@resumiq.ai
            </Button>
            .
          </Text>
        </Stack>
      </Box>
      <Footer />
    </>
  );
};

export default Privacy;
