import React from "react";
import {
  Box,
  Heading,
  Text,
  Flex,
  Stack,
  Container,
  TableContainer,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Table,
  Button,
} from "@chakra-ui/react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { FaCircle } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const SystemStatus = () => {
  const navigate = useNavigate();
  const knownIssues = [
    //example
    // {
    //   date: '2024-07-15',
    //   summary: 'System Update',
    //   duration: '2 hours',
    //   resolution: 'Update completed successfully',
    //   status: 'Operational',
    // },
    // Add more sample data as needed
  ];

  const releaseNotesData = [];

  return (
    <>
      <Navbar />
      <Box minH={"100vh"} bg="primaryBackground" color={"text"}>
        <Stack as={Container} maxW={"3xl"}>
          <Heading as="h1" mt={20}>
            System Status
          </Heading>
          <Text mt={4} pb={10}>
            This page provides an overview of the current status of our
            application and recent updates.
          </Text>

          <Text fontSize="lg" fontWeight="bold" mr={2}>
            Overall Status
          </Text>
          <Flex align="center" pb={10}>
            <Box as={FaCircle} color="green.500" boxSize={4} mr={1} />
            <Text>Operational</Text>
          </Flex>

          {/* KNOWN ISSUES */}
          <Text fontSize="lg" fontWeight="bold" mr={2}>
            Known Issues
          </Text>
          <TableContainer>
            <Table variant="striped" colorScheme="grey">
              <Thead>
                <Tr>
                  <Th>Date</Th>
                  <Th>Summary</Th>
                  <Th>Duration</Th>
                  <Th>Resolution</Th>
                  <Th>Status</Th>
                </Tr>
              </Thead>
              <Tbody>
                {knownIssues.length === 0 ? (
                  <Tr>
                    <Td colSpan={5}>
                      <Text textAlign="center">
                        No issues in the past 30 days.
                      </Text>
                    </Td>
                  </Tr>
                ) : (
                  knownIssues.map((row, index) => (
                    <Tr key={index}>
                      <Td>{row.date}</Td>
                      <Td>{row.summary}</Td>
                      <Td>{row.duration}</Td>
                      <Td>{row.resolution}</Td>
                      <Td>{row.status}</Td>
                    </Tr>
                  ))
                )}
              </Tbody>
            </Table>
          </TableContainer>
          <Text fontSize={"sm"}>
            *Some issues affecting a small percentage of users may not be
            reflected here.
          </Text>
          <Button
            variant="primary"
            maxW={{ base: "none", md: "25%" }}
            mb={10}
            onClick={() => {
              navigate("/contact");
            }}
          >
            Report an Issue
          </Button>

          {/* RELEASE NOTES */}
          <Text fontSize="lg" fontWeight="bold" mr={2}>
            Release Notes
          </Text>
          <TableContainer>
            <Table variant="striped" colorScheme="grey">
              <Thead>
                <Tr>
                  <Th>Version</Th>
                  <Th>Date</Th>
                  <Th>Change</Th>
                </Tr>
              </Thead>
              <Tbody>
                {releaseNotesData.length === 0 ? (
                  <Tr>
                    <Td colSpan={3}>
                      <Text textAlign="center">No release notes available</Text>
                    </Td>
                  </Tr>
                ) : (
                  releaseNotesData.map((note, index) => (
                    <Tr key={index}>
                      <Td>{note.version}</Td>
                      <Td>{note.date}</Td>
                      <Td>{note.change}</Td>
                    </Tr>
                  ))
                )}
              </Tbody>
            </Table>
          </TableContainer>
        </Stack>
      </Box>

      <Footer />
    </>
  );
};

export default SystemStatus;
