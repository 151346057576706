"use client";

import {
  Box,
  Flex,
  HStack,
  IconButton,
  Button,
  useDisclosure,
  Stack,
  Menu,
  MenuButton,
  Avatar,
  MenuList,
  MenuDivider,
  MenuItem,
  Center,
  Heading,
  Image,
} from "@chakra-ui/react";
import React from "react";
import { HamburgerIcon, CloseIcon } from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";

import logo from "../assets/resumiq-orange.png";

export default function Navbar() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigate = useNavigate();

  const links = [
    {
      name: "Home",
      link: "/",
    },
    {
      name: "Pricing",
      link: "/pricing",
    },
    {
      name: "Contact",
      link: "/contact",
    },
  ];

  return (
    <>
      <Box bg={"tertiaryBackground"} px={4} py={2}>
        <Flex h={16} alignItems={"center"} justifyContent={"space-between"}>
          <IconButton
            size={"md"}
            icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
            aria-label={"Open Menu"}
            display={{ md: "none" }}
            onClick={isOpen ? onClose : onOpen}
          />
          <HStack spacing={8} alignItems={"center"}>
            <Box as="button" onClick={() => navigate("/")} cursor="pointer">
              <Image src={logo} width={"200px"} />
            </Box>
          </HStack>
          <HStack
            // pl={20}
            as={"nav"}
            spacing={10}
            display={{ base: "none", md: "flex" }}
          >
            {links.map((link) => (
              <Button
                variant={"navLanding"}
                fontWeight={400}
                onClick={() => navigate(link.link)}
                fontSize={17}
              >
                {link.name}
              </Button>
            ))}
          </HStack>
          <Flex alignItems={"center"}>
            <Stack
              flex={{ base: 1, md: 0 }}
              justify={"flex-end"}
              direction={"row"}
              spacing={6}
            >
              <Button
                as={"a"}
                color={"black"}
                fontSize={"sm"}
                fontWeight={400}
                variant={"link"}
                href="https://app.resumiq.ai/signin"
              >
                Sign In
              </Button>
              <Button
                as={"a"}
                display={{ base: "none", md: "inline-flex" }}
                fontSize={"sm"}
                fontWeight={600}
                variant={"primary"}
                href="https://app.resumiq.ai/register"
              >
                Try for free
              </Button>
            </Stack>
          </Flex>
        </Flex>

        {isOpen ? (
          <Box pb={4} display={{ md: "none" }}>
            <Stack as={"nav"} spacing={4}>
              {links.map((link) => (
                <Button
                  variant={"navLanding"}
                  key={link.link}
                  onClick={() => navigate(link.link)}
                  fontWeight={400}
                >
                  {link.name}
                </Button>
              ))}
            </Stack>
          </Box>
        ) : null}
      </Box>
    </>
  );
}
