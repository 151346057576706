import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    const handleLinkClick = (event) => {
      const { href } = event.target.closest("a") || {};
      if (href && href.endsWith(pathname)) {
        window.scrollTo(0, 0);
      }
    };

    window.addEventListener("click", handleLinkClick);

    return () => {
      window.removeEventListener("click", handleLinkClick);
    };
  }, [pathname]);

  return null;
};

export default ScrollToTop;
