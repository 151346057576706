import logo from "./logo.svg";
import "./App.css";
import { ChakraProvider } from "@chakra-ui/react";
import customTheme from "./design/customTheme";
import Landing from "./pages/Landing";
import Pricing from "./pages/Pricing";
import Contact from "./pages/Contact";
import { Route } from "react-router-dom";
import { Routes } from "react-router-dom";
import Terms from "./pages/Terms";
import Privacy from "./pages/Privacy";
import ScrollToTop from "./components/ScrollToTop";
import SystemStatus from "./pages/SystemStatus";

function App() {
  return (
    <ChakraProvider theme={customTheme}>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/system-status" element={<SystemStatus />} />
      </Routes>
    </ChakraProvider>
  );
}

export default App;
