import React from "react";
import { Container, Heading, Text, Stack, Button, Box } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

const Terms = () => {
  const navigate = useNavigate();
  return (
    <>
      <Navbar />
      <Box bg="primaryBackground">
        <Stack spacing={4} as={Container} maxW={"3xl"} py={20}>
          <Heading fontSize="3xl" fontWeight={600} color={"heading"}>
            Terms and Conditions for Resumiq.ai
          </Heading>
          <Text color={"text"} fontSize={"lg"}>
            Last Updated: July 2, 2024
          </Text>

          <Text color={"text"} fontSize={"lg"}>
            Welcome to Resumiq.ai, an AI-powered resume review platform
            ("Resumiq.ai"). These Terms and Conditions of Use ("Terms") govern
            your access to and use of Resumiq.ai. By accessing or using
            Resumiq.ai, you agree to be bound by these Terms.
          </Text>
          <Heading fontSize="xl" fontWeight={600} color={"heading"}>
            1. Description of Service
          </Heading>

          <Text color={"text"} fontSize={"lg"}>
            Resumiq.ai provides an AI-powered platform that offers resume review
            services. Our service includes analyzing and providing feedback on
            resumes submitted by users.
          </Text>

          <Heading fontSize="xl" fontWeight={600} color={"heading"}>
            2. Use of Resumiq.ai
          </Heading>
          <Text color={"text"} fontSize={"lg"}>
            <Heading fontSize="lg" fontWeight={600} color={"heading"}>
              2.1 Eligibility
            </Heading>
            You must be at least 16 years old to use Resumiq.ai. By using
            Resumiq.ai, you represent and warrant that you are at least 16 years
            old.
          </Text>
          <Heading fontSize="lg" fontWeight={600} color={"heading"}>
            2.2 Account Registration
          </Heading>
          <Text color={"text"} fontSize={"lg"}>
            To access certain features of Resumiq.ai, you may be required to
            register for an account. You agree to provide accurate and complete
            information during the registration process and to keep your account
            information up to date.
          </Text>
          <Heading fontSize="lg" fontWeight={600} color={"heading"}>
            2.3 Acceptable Use
          </Heading>
          <Text color={"text"} fontSize={"lg"}>
            You agree to use Resumiq.ai only for lawful purposes and in
            accordance with these Terms. You may not use Resumiq.ai in any way
            that violates any applicable laws or regulations.
          </Text>

          <Heading fontSize="xl" fontWeight={600} color={"heading"}>
            3. Intellectual Property Rights
          </Heading>
          <Heading fontSize="lg" fontWeight={600} color={"heading"}>
            3.1 Ownership
          </Heading>
          <Text color={"text"} fontSize={"lg"}>
            Resumiq.ai and its content, including but not limited to text,
            graphics, logos, images, and software, are owned by Resumiq.ai or
            its licensors and are protected by copyright, trademark, and other
            intellectual property laws.
          </Text>
          <Heading fontSize="lg" fontWeight={600} color={"heading"}>
            3.2 License
          </Heading>
          <Text color={"text"} fontSize={"lg"}>
            Resumiq.ai grants you a limited, non-exclusive, non-transferable
            license to use Resumiq.ai for your personal, non-commercial use,
            unless otherwise agreed upon under a Partnership Program agreement.
          </Text>

          <Heading fontSize="xl" fontWeight={600} color={"heading"}>
            4. Privacy Policy
          </Heading>
          <Text color={"text"} fontSize={"lg"}>
            Your privacy is important to us. Please review our{" "}
            <Button
              as={"a"}
              onClick={() => {
                navigate("/privacy");
              }}
              variant="link"
              color={"text"}
            >
              Privacy Policy
            </Button>
            , which explains how we collect, use, and disclose information about
            you when you use Resumiq.ai.
          </Text>

          <Heading fontSize="xl" fontWeight={600} color={"heading"}>
            5. Disclaimer of Warranties
          </Heading>
          <Text color={"text"} fontSize={"lg"}>
            Resumiq.ai is provided "as is" and "as available" without any
            warranties of any kind, express or implied. We do not warrant that
            Resumiq.ai will be uninterrupted or error-free, or that any defects
            will be corrected.
          </Text>

          <Heading fontSize="xl" fontWeight={600} color={"heading"}>
            6. Limitation of Liability
          </Heading>
          <Text color={"text"} fontSize={"lg"}>
            In no event shall Resumiq.ai be liable for any indirect, incidental,
            special, consequential, or punitive damages, arising out of or in
            connection with your use of Resumiq.ai.
          </Text>

          <Heading fontSize="xl" fontWeight={600} color={"heading"}>
            7. Changes to Terms
          </Heading>
          <Text color={"text"} fontSize={"lg"}>
            Resumiq.ai reserves the right to modify or update these Terms at any
            time without prior notice. Your continued use of Resumiq.ai after
            any such changes constitutes your acceptance of the revised Terms.
          </Text>

          <Heading fontSize="xl" fontWeight={600} color={"heading"}>
            8. Governing Law
          </Heading>
          <Text color={"text"} fontSize={"lg"}>
            These Terms are governed by the laws of Canada, without regard to
            its conflict of laws principles.
          </Text>
          <Heading fontSize="xl" fontWeight={600} color={"heading"}>
            9. Contact Us
          </Heading>
          <Text color={"text"} fontSize={"lg"}>
            If you have any questions about these Terms, please contact us at
            <Button
              as="a"
              href="mailto:contact@resumiq.ai"
              variant="link"
              color={"text"}
            >
              contact@resumiq.ai
            </Button>
            .
          </Text>
        </Stack>
      </Box>
      <Footer />
    </>
  );
};

export default Terms;
