import { border, extendTheme } from "@chakra-ui/react";

const customTheme = extendTheme({
  fonts: {
    body: "Montserrat, sans-serif",
    heading: "Montserrat, sans-serif",
  },
  colors: {
    primaryBackground: "#f3f3f3",
    // secondaryBackground: "#1e1e23",
    secondaryBackground: "#fafafa",
    tertiaryBackground: "#ffffff",
    primaryHighlight: "#ff550f",
    secondaryHighlight: "#0057E1",
    text: "#203853",
    heading: "#203853",
    white: "#FFFFFF",
    // lightGray: "#5A5A5A",
    lightGray: "#c6c6c6",
    warning: "#E53E3E",
    darkGray: "#6A6A7E",
  },

  components: {
    Button: {
      variants: {
        primary: {
          bg: "primaryHighlight",
          color: "white",
          borderRadius: "full",
          _hover: {
            // bg: "#0057E1",
            bg: "#153e6c",
          },
          _disabled: {
            bg: "primaryHighlight",
            cursor: "not-allowed",
            _hover: {
              bg: "primaryHighlight !important",
            },
          },
        },
        secondary: {
          bg: "secondaryHighlight",
          color: "white",
          _hover: {
            bg: "#ffa27c",
          },
          _disabled: {
            bg: "secondaryHighlight",
            cursor: "not-allowed",
            _hover: {
              bg: "primaryHighlight !important",
            },
          },
        },
        outline: {
          borderColor: "primaryHighlight",
          color: "primaryHighlight",
          _hover: {
            borderColor: "primaryHighlight",
            color: "primaryHighlight",
            bg: "#ffd8c8",
          },
        },
        secondaryOutline: {
          borderColor: "secondaryHighlight",
          color: "secondaryHighlight",
          borderWidth: "1px",
          _hover: {
            borderColor: "secondaryHighlight",
            color: "secondaryHighlight",
            bg: "#d2f0fe",
          },
        },
        outlineNoBorder: {
          borderColor: "none",
          color: "lightGray",
          _hover: {
            borderColor: "none",
            color: "white",
            bg: "none",
          },
        },

        nav: {
          color: "white",
          _hover: {
            color: "#c1c8d2",
          },
        },
        navLanding: {
          color: "black",
          _hover: {
            color: "gray.400",
          },
        },
        link: {
          color: "text",
          _hover: {
            color: "text",
            textDecoration: "underline",
          },
        },
        linkSecondary: {
          color: "black",
          textDecoration: "underline",
          _hover: {
            color: "black",
            textDecoration: "none",
          },
        },
        stealth: {
          bg: "transparent",
          color: "black",

          _hover: {
            bg: "gray.100",
            color: "black",
          },
        },
        google: {
          // border: "1px solid",
          // borderColor: "lightGray",
          bg: "#4285f4",
          color: "white",
          _hover: {
            // border: "1px solid",
            // borderColor: "lightGray",
            bg: "#a9c7fa",
            color: "white",
          },
        },
      },
    },

    Input: {
      baseStyle: {
        bg: "transparent",
        color: "text !important",
      },
      sizes: {},
      variants: {
        outline: {
          field: {
            borderColor: "lightGray",
            _hover: {
              borderColor: "lightGray",
            },
            _focus: {
              borderColor: "primaryHighlight",

              boxShadow: "primaryHighlight",
              outline: "1px solid primaryHighlight !important",
            },
          },
        },
      },
      defaultProps: {
        variant: "outline",
        color: "text",
        focusBorderColor: "primaryHighlight",
      },
    },

    TextArea: {
      baseStyle: {
        bg: "transparent",
        color: "text !important",
      },
      sizes: {},
      variants: {
        outline: {
          field: {
            borderColor: "lightGray",
            _hover: {
              borderColor: "lightGray",
            },
            _focus: {
              borderColor: "primaryHighlight",
              boxShadow: "primaryHighlight",
              outline: "1px solid primaryHighlight !important",
            },
          },
        },
      },
      defaultProps: {
        variant: "outline",
        color: "text",
        focusBorderColor: "primaryHighlight",
      },
    },

    Progress: {
      baseStyle: {
        filledTrack: {
          bg: "primaryHighlight",
        },
      },
    },
  },
});

export default customTheme;
